import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TextObj } from "../../text";
import { removeBundles, selectBundles } from "../../reducers/push-message";

export const BundleSelector = (props) => {
  const dispatch = useDispatch();
  const { bundles, bundleIds } = useSelector((state) => state.push);
  const { skip, type } = props;

  const handleSelectedItemChange = (selectedItems) => {
    dispatch(selectBundles(selectedItems[selectedItems.length - 1]));
  };

  const handleRemoveBundles = (selectedItem) => {
    dispatch(removeBundles(selectedItem));
  };

  const filterBundlesOptions = (inputValue, option) => {
    return option.children.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div>
      <Select
        mode={type}
        value={bundleIds}
        onChange={handleSelectedItemChange}
        onDeselect={handleRemoveBundles}
        filterOption={filterBundlesOptions}
        style={{ width: "100%" }}
        showSearch={true}
        allowClear={type === "multiple" ? false : true}
      >
        {bundles?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
      {bundleIds.length === 0 && !skip && (
        <span style={{ color: "red" }}>{TextObj.BUNDLE_SEELCTOR}</span>
      )}
    </div>
  );
};
