import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  bundles: [],
  bundleIds: [],
  bundlesActionStart: false,
  pushMessageActionStart: false,
  userIds: [],
  title: "",
  description: "",
  channel: "",
  selectedId: null,
  searchText: "",
};

export const pushMessageSlice = createSlice({
  name: "pushMessage",
  initialState,
  reducers: {
    setPushMessages: (state, action) => {
      state.messages = action.payload;
    },
    setBundles: (state, action) => {
      state.bundles = action.payload;
    },
    createMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
    setPushMessageActionStart: (state) => {
      state.pushMessageActionStart = true;
    },
    setPushMessageActionFinished: (state) => {
      state.pushMessageActionStart = false;
    },
    setBundlesActionStart: (state) => {
      state.bundlesActionStart = true;
    },
    setBundlesActionFinished: (state) => {
      state.bundlesActionStart = false;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
      console.log("setTitle");
    },
    setMessage: (state, action) => {
      state.description = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    selectUsers: (state, action) => {
      state.userIds = [...state.userIds, action.payload];
    },
    selectBundles: (state, action) => {
      state.bundleIds = [...state.bundleIds, action.payload];
    },
    removeUsers: (state, action) => {
      state.userIds = Array.from(new Set(state.userIds)).filter(
        (id) => id !== action.payload && id !== undefined
      );
    },
    removeBundles: (state, action) => {
      state.bundleIds = Array.from(new Set(state.bundleIds)).filter(
        (id) => id !== action.payload && id !== undefined
      );
    },
    clearPushMessage: (state) => {
      console.log("clearPushMessage");
      state.title = "";
      state.description = "";
      state.channel = "";
      state.userIds = [];
      state.bundleIds = [];
      state.searchText = "";
    },
  },
});

export const {
  setPushMessages,
  setBundles,
  createMessage,
  deleteMessage,
  setPushMessageActionStart,
  setPushMessageActionFinished,
  setBundlesActionStart,
  setBundlesActionFinished,
  setTitle,
  setMessage,
  setChannel,
  selectUsers,
  selectBundles,
  removeBundles,
  clearPushMessage,
  removeUsers,
  setSearchText,
} = pushMessageSlice.actions;
