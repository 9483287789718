import {
  setBundles,
  setBundlesActionStart,
  setBundlesActionFinished,
} from "../../constants/reducers/push-message";
import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
export const getAllBoundles = async () => {
  store.dispatch(setBundlesActionStart());

  const dataToSend = {
    url: "dictionary/boundles-all",
    method: BASE_METHODS.GET,
    contentType: "application/json",
  };
  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setBundles(response.data.bundles));
    store.dispatch(setBundlesActionFinished());
    return response;
  } catch (error) {
    store.dispatch(setBundlesActionFinished());
    return null;
  }
};
