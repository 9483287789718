import { message } from "antd";
import {
  clearPushMessage,
  createMessage,
  setPushMessageActionFinished,
  setPushMessageActionStart,
} from "../../constants/reducers/push-message";
import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";

export const createPushMessage = async (bundles) => {
  store.dispatch(setPushMessageActionStart());
  const { userIds, bundleIds, title, description, channel } =
    store.getState().push;
  const params = {
    userIds,
    title,
    message: description,
    channel,
    messageId: "000",
  };

  const bundlesParams = {
    bundleIds,
    title,
    message: description,
    channel,
    messageId: "000",
  };

  const dataToSend = {
    url: "message/create",
    method: BASE_METHODS.POST,
    contentType: "application/json",
    body: bundles ? bundlesParams : params,
  };
  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(createMessage(response.data));
    store.dispatch(clearPushMessage());
    store.dispatch(setPushMessageActionFinished());
    message.success("Sikeres mentés");
    return response;
  } catch (error) {
    store.dispatch(setPushMessageActionFinished());
    return;
  }
};
